import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-info-popover",
  templateUrl: "./info-popover.component.html",
  styleUrls: ["./info-popover.component.scss"]
})
export class InfoPopoverComponent implements OnInit {
  @Input() info: string;
  @Input() fullscreen: boolean = false;

  constructor() {}

  ngOnInit() {}
}
