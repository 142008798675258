import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import {
  ServiceWorkerModule,
  SwRegistrationOptions
} from "@angular/service-worker";
import { environment } from "../environments/environment";
import { HashLocationStrategy, LocationStrategy } from "@angular/common";
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule
} from "@angular/common/http";
import { StampPage } from "./pages/stamp/stamp.page";
import { StampStackComponent } from "./components/stamp-stack/stamp-stack.component";
import { VoucherComponent } from "./components/voucher/voucher.component";
import { InfoPopoverComponent } from "./components/info-popover/info-popover.component";
import { ShowUrlComponent } from "./components/show-url/show-url.component";
import { FormsModule } from "@angular/forms";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { SupportedLanguages } from "./models/constants/languages";
import { ErrorHandler } from "./services/error-handler/error-handler";
import { SelectFullscreenComponent } from "./components/select-fullscreen/select-fullscreen.component";

export function createTranslateLoader(http: HttpClient) {
  if (environment.production === false) {
    // return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
  }
  return new TranslateHttpLoader(
    http,
    "https://city-stamp.de/assets/i18n/",
    ".json"
  );
}

@NgModule({
  declarations: [
    AppComponent,
    StampStackComponent,
    VoucherComponent,
    StampPage,
    InfoPopoverComponent,
    ShowUrlComponent,
    SelectFullscreenComponent
  ],
  entryComponents: [
    StampPage,
    InfoPopoverComponent,
    ShowUrlComponent,
    SelectFullscreenComponent
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      rippleEffect: false,
      mode: "md"
    }),
    TranslateModule.forRoot({
      defaultLanguage: SupportedLanguages.DE,
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    AppRoutingModule,
    HttpClientModule,
    ServiceWorkerModule.register("ngsw-worker.js"),
    FormsModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: SwRegistrationOptions,
      useFactory: () => ({ enabled: environment.production })
    },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorHandler, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
