import m_Image from "./Image.model";
import m_Loaylity from './Loyality.model';
import m_Address from './Address.model';

class m_Partner{

    id: number;
    url: string;
    name: string;
    theme_color: string;
    address_id: string;
    invoice_address_id: string;
    is_active: boolean;
    created_at: string;
    updated_at: string;
    deleted_at: string;
    reseller_id: string;
    is_no_invoice_address: boolean;
    show_logo: boolean;
    show_name: boolean;
    show_gtc: boolean;
    show_impress: boolean;
    has_analog_stamp: boolean;
    sector_id: string;
    bundle_id: string;
    website: string;
    address: m_Address;
    invoice_address: m_Address;
    logo: m_Image;
    header_image: m_Image;
    loyalty_programs: m_Loaylity[];
    opening_times: any[];
    social_media_links: any[];
    stamps: any[];
    sector: string;
    agb_content: string;
    impress_content: string;
    custom_opening_hours_text: string;
    has_own_opening_hours: boolean;

    constructor(
        id: number,
        url: string,
        name: string,
        theme_color: string,
        address_id: string,
        invoice_address_id: string,
        is_active: boolean,
        has_analog_stamp: boolean,
        created_at: string,
        updated_at: string,
        deleted_at: string,
        reseller_id: string,
        is_no_invoice_address: boolean,
        sector_id: string,
        bundle_id: string,
        website: string,
        address: m_Address,
        invoice_address: m_Address,
        logo: m_Image,
        header_image: m_Image,
        loyalty_programs: m_Loaylity[],
        opening_times: [],
        social_media_links: [],
        stamps: [],
        sector: string,
        agb_content: string,
        impress_content: string,
        custom_opening_hours_text: string,
        has_own_opening_hours: boolean
    ){
        this.id = id;
        this.url = url;
        this.name = name;
        this.theme_color = theme_color;
        this.address_id = address_id;
        this.invoice_address_id = invoice_address_id;
        this.is_active = is_active;
        this.created_at = created_at;
        this.updated_at = updated_at;
        this.deleted_at = deleted_at;
        this.has_analog_stamp = has_analog_stamp;
        this.reseller_id = reseller_id;
        this.is_no_invoice_address = is_no_invoice_address;
        this.sector_id = sector_id;
        this.bundle_id = bundle_id;
        this.website = website;
        this.address = address;
        this.invoice_address = invoice_address;
        this.logo = logo;
        this.header_image = header_image;
        this.loyalty_programs = loyalty_programs;
        this.opening_times = opening_times;
        this.social_media_links = social_media_links;
        this.stamps = stamps;
        this.sector = sector;
        this.agb_content = agb_content;
        this.custom_opening_hours_text = custom_opening_hours_text;
        this.has_own_opening_hours = has_own_opening_hours;
        this.impress_content = impress_content;
    }

}

export default m_Partner;
