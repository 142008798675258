import { Injectable } from "@angular/core";
import { HttpService } from "../http/http.service";
import { Observable } from "rxjs";
import m_Credentials from "../../models/credentials.model";

@Injectable({
  providedIn: "root"
})
export class ApiService {
  constructor(private http: HttpService) {}

  loginUser(credentials: m_Credentials, partner_id: number): Observable<any> {
    const params = {
      email: credentials.email,
      password: credentials.password,
      partner_id: partner_id.toString()
    };
    return this.http.post("/login", params);
  }

  registerUser(params: any, partner_id: number): Observable<any> {
    params.partner_id = partner_id.toString();
    return this.http.post("/register", params);
  }

  addUser(partner_id: number): Observable<any> {
    return this.http.post("/user/partners/" + partner_id + "/add");
  }

  resetPassword(
    code: string,
    password: string,
    password2: string
  ): Observable<any> {
    const params = {
      password: password,
      password_confirmation: password2,
      code: code
    };
    return this.http.post("/restore-password", params);
  }

  restorePassword(email: string): Observable<any> {
    const params = {
      email: email
    };
    return this.http.post("/reset-password", params);
  }

  changePassword(password: string, code: string): Observable<any> {
    const params = {
      password: password,
      code: code
    };
    return this.http.post("/reset-password", params);
  }

  updateToken(): Observable<any> {
    return this.http.post("/refresh");
  }

  updateUser(params: any): Observable<any> {
    return this.http.post("/user/update-profile", params);
  }

  getUser(): Observable<any> {
    return this.http.post("/user/detail");
  }

  resendVerification(partner_id): Observable<any> {
    const params = {
      partner_id: partner_id.toString()
    };
    return this.http.post("/user/send-verification-mail", params);
  }

  getPartnerbySlug(slug: string): Observable<any> {
    return this.http.get("/partners/" + slug);
  }

  getloyals(id: number): Observable<any> {
    return this.http.get("/partners/" + id + "/loyalty-programs");
  }

  getlocations(id: number): Observable<any> {
    return this.http.get("/partners/" + id + "/locations");
  }

  getloyalsAmount(id: number): Observable<any> {
    return this.http.getWithHeader("/user/loyalty-programs/" + id + "/stamps");
  }

  stampLoyality(id: number, stamp = null, count: number = 1): Observable<any> {
    let stamp_id = 0;
    if (stamp) {
      stamp_id = stamp.id;
    }
    const params = {
      stamp_id: stamp_id,
      stamp_count: count
    };
    return this.http.post("/user/loyalty-programs/" + id + "/stamp", params);
  }

  stampLog(
    device: string,
    stamp_content: string,
    result: string,
    code: string,
    partner_id: number
  ): Observable<any> {
    const params = {
      device,
      stamp_content,
      result,
      code,
      partner_id
    };
    return this.http.post("/user/log/stamp", params);
  }

  sendErrorCodeLoyality(
    partner_id: number,
    code: string,
    stamp_count: number,
    loyaltyprogram_id: number
  ): Observable<any> {
    const params = {
      code: code,
      partner_id: partner_id,
      stamp_count: stamp_count
    };
    return this.http.post(
      "/user/loyalty-programs/" + loyaltyprogram_id + "/stamp/code",
      params
    );
  }

  sendErrorCodeOffer(
    partner_id: number,
    code: string,
    offer_id: number
  ): Observable<any> {
    const params = {
      code: code,
      partner_id: partner_id,
      offer_id: offer_id
    };

    return this.http.post(
      "/user/partners/" + partner_id + "/offers/" + offer_id + "/redeem",
      params
    );
  }

  sendErrorCodeReward(
    partner_id: number,
    code: string,
    offer_id: number
  ): Observable<any> {
    const params = {
      code: code,
      partner_id: partner_id,
      offer_id: offer_id
    };

    return this.http.post(
      "/user/partners/" + partner_id + "/rewards/" + offer_id + "/redeem/code",
      params
    );
  }

  getoffers(id: number): Observable<any> {
    return this.http.get("/partners/" + id + "/offers");
  }

  getQR(id: number): Observable<any> {
    return this.http.get("/user/loyalty-programs/" + id + "/qr-code");
  }

  getReward(id: number, reward_id: number): Observable<any> {
    const params = {
      reward_id: reward_id
    };
    return this.http.post(
      "/user/loyalty-programs/" + id + "/redeem-reward",
      params
    );
  }

  getQROffer(partner_id: number, offer_id: number): Observable<any> {
    return this.http.get(
      "/user/partners/" + partner_id + "/offers/" + offer_id + "/qr-code"
    );
  }

  redeemOffer(
    partner_id: number,
    offer_id: number,
    stamp = null
  ): Observable<any> {
    let stamp_id = 0;
    if (stamp) {
      stamp_id = stamp.id;
    }
    const params = {
      stamp_id: stamp_id
    };
    return this.http.post(
      "/user/partners/" + partner_id + "/offers/" + offer_id + "/redeem",
      params
    );
  }

  getQRReward(reward_id: number): Observable<any> {
    return this.http.get("/user/rewards/" + reward_id + "/qr-code");
  }

  getRewards(id: number): Observable<any> {
    return this.http.get("/user/partners/" + id + "/rewards");
  }

  redeemReward(
    partner_id: number,
    offer_id: number,
    stamp = null
  ): Observable<any> {
    let stamp_id = 0;
    if (stamp) {
      stamp_id = stamp.id;
    }
    const params = {
      stamp_id: 162
    };
    return this.http.post(
      "/user/partners/" + partner_id + "/rewards/" + offer_id + "/redeem",
      params
    );
  }
}
