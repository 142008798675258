import m_User from "./User.model";

class m_User_With_Token {

    token: string;
    user: m_User

    constructor(
        user: m_User
    ) {
        this.user = user;
    }
}

export default m_User_With_Token;
