import {Inject, Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";
import {Router} from "@angular/router";
import {map, tap} from "rxjs/operators";
import {ApiService} from "../api/api.service";
import {StorageService} from "../storage/storage.service";
import m_Credentials from "src/app/models/credentials.model";
import m_User_With_Token from "../../models/UserWithToken.model";
import m_Partner from "../../models/Partner.model";
import {DOCUMENT} from "@angular/common";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  static _userToken = "";
  _partner: m_Partner = null;

  constructor(
    private api: ApiService,
    private storage: StorageService,
    private router: Router,
    @Inject(DOCUMENT) private document: Document
  ) {}

  private _user = new BehaviorSubject<m_User_With_Token>(null);

  get user() {
    return this._user.asObservable().pipe(
      map((user) => {
        if (user) {
          return user;
        } else {
          return null;
        }
      })
    );
  }

  private _userIsAuthenticated = false;

  get userIsAuthenticated() {
    return this._user.asObservable().pipe(
      map((user) => {
        if (user) {
          return !!user.token;
        } else {
          return false;
        }
      })
    );
  }

  autoLogin(): Observable<any> {
    return Observable.create((observer) => {
      this.storage.get("authData_" + StorageService.BaseURL).then(
        (data) => {
          if (!data.value) return;

          const user = JSON.parse(data.value);
          this._user.next(new m_User_With_Token(user));

          AuthService._userToken = user.token;

          StorageService._userToken = user.token;
          StorageService.User.next(user);

          this.api.updateToken().subscribe(
            (data) => {
              this._user.next(new m_User_With_Token(data.response));
              this.saveUserData(data);
              observer.next(user);
              observer.complete();
            },
            (error) => {
              this.logout();
            }
          );
        },
        (err) => {}
      );
    });
  }

  login(email: string, password: string, partner_id: number) {
    const cred: m_Credentials = {
      email: email,
      password: password,
    };
    return this.api
      .loginUser(cred, partner_id)
      .pipe(tap(this.saveUserData.bind(this)));
  }

  public saveUserData(dataResp: any) {
    this._user.next(new m_User_With_Token(dataResp.response));
    AuthService._userToken = dataResp.response.token;
    StorageService._userToken = dataResp.response.token;
    StorageService.User.next(dataResp.response.user);

    this.storage.get("autoLogin_" + StorageService.BaseURL).then((resp) => {
      const autoLogin = JSON.parse(resp.value).autoLogin;
      if (autoLogin) {
        this.storage.set(
          "authData_" + StorageService.BaseURL,
          dataResp.response
        );
      }
    });
  }

  public logout() {
    this.storage.destroy();
    this._user.next(null);
    this.router.navigate(["login"]);
    this.storage
      .remove("authData_" + StorageService.BaseURL)
      .then((data) => {});
    this.storage
      .remove("autoLogin_" + StorageService.BaseURL)
      .then((data) => {});
  }
}
