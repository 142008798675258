import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {DomSanitizer} from "@angular/platform-browser";
import {ModalController} from "@ionic/angular";

@Component({
  selector: 'app-show-url',
  templateUrl: './show-url.component.html',
  styleUrls: ['./show-url.component.scss'],
})
export class ShowUrlComponent implements OnInit {

  @Input() url: any;

  _url : any;
  constructor(  private activatedRoute: ActivatedRoute,
                private sanitizer: DomSanitizer,
                private modalCtrl: ModalController) {

  }

  ngOnInit() {
    this._url =  this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }

  dismiss() {
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }


}
