import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-stamp-stack',
  templateUrl: './stamp-stack.component.html',
  styleUrls: ['./stamp-stack.component.scss'],
})
export class StampStackComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
