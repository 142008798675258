import {Component, Input, OnInit} from "@angular/core";
import {StorageService} from "src/app/services/storage/storage.service";
import m_Partner from "src/app/models/Partner.model";
import {
  LoadingController,
  ModalController,
  NavController,
  Platform,
  ToastController,
} from "@ionic/angular";
import {AuthService} from "../../services/auth/auth.service";
import {SharedService} from "../../services/shared/shared.service";
import {ActivatedRoute} from "@angular/router";
import {ApiService} from "../../services/api/api.service";
import StampType from "../../models/StampType.model";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: "app-stamp",
  templateUrl: "./stamp.page.html",
  styleUrls: ["./stamp.page.scss"],
})
export class StampPage implements OnInit {
  @Input() stampStack: any;
  @Input() partner: m_Partner;
  @Input() type: StampType;
  _qrCode: string;
  _success: boolean = false;
  _loaded: boolean = false;
  _read: boolean = false;
  _error: boolean = false;
  _errorInput: boolean = false;
  _errorInput_value = "";
  _error_pass = false;
  _label: string;
  _lastScanned: any[];
  number = 0;
  number2 = 0;
  number3 = 0;
  stampCount = 1;
  stampMax = 10;

  _lock: boolean = false;
  _settings: boolean = false;

  result = "";

  constructor(
    private authService: AuthService,
    private navCtrl: NavController,
    private loadingCtrl: LoadingController,
    private sharedService: SharedService,
    private storage: StorageService,
    private route: ActivatedRoute,
    private api: ApiService,
    private platform: Platform,
    private toastCtrl: ToastController,
    private modalCtrl: ModalController,
    private translate: TranslateService
  ) {}

  ionViewDidEnter() {
    this._lock = false;
    let canvas = <HTMLCanvasElement>document.getElementById("canvas");
    var _this = this;

    canvas.addEventListener("touchmove", function (event) {
      event.preventDefault();
    });
    canvas.addEventListener("gesturechange", function (event) {
      event.preventDefault();
    });

    canvas.addEventListener(
      "touchstart",
      function (event) {
        event.preventDefault();

        if (event.touches.length == 5) {
          _this._read = true;
          if (!_this._lock) {
            _this._lock = true;
            setTimeout(function () {
              _this._lock = false;
              _this.processStamp(event);
            }, 1000);
          }
        }
      },
      false
    );
  }

  ngOnInit() {
    // fake an array of 5 touches to trigger the stamp
    // let touches = [];
    // for (let i = 0; i < 5; i++) {
    //   touches.push({ pageX: 0, pageY: 0 });
    // }
    // let event = { touches: touches };
    // this.processStamp(<TouchEvent>(<unknown>event));

    switch (this.type) {
      case StampType.Stamp:
        this.api.getQR(this.stampStack.id).subscribe(
          (data) => {
            this._loaded = true;
            this._qrCode = "data:image/jpeg;base64," + data;
          },
          (error) => {
            this.sharedService.simpleAlert("Fehler", error.errorMessage).then();
          }
        );

        this._label = this.translate.instant("redeem_stamp", {
          value: this.partner.name,
        });
        this.stampMax = this.stampStack.stamp_per_day;
        break;
      case StampType.Offer:
        this.api.getQROffer(this.partner.id, this.stampStack.id).subscribe(
          (data) => {
            this._loaded = true;
            this._qrCode = "data:image/jpeg;base64," + data;
          },
          (error) => {
            this.sharedService.simpleAlert("Fehler", error.errorMessage).then();
          }
        );
        this._label = this.translate.instant("redeem_offer", {
          value: this.stampStack.name,
        });
        break;
      case StampType.Voucher:
        this.api.getQRReward(this.stampStack.id).subscribe(
          (data) => {
            this._loaded = true;
            this._qrCode = "data:image/jpeg;base64," + data;
          },
          (error) => {
            this.sharedService.simpleAlert("Fehler", error.errorMessage).then();
          }
        );
        this._label = this.translate.instant("redeem_voucher", {
          value: this.stampStack.name,
        });
        break;
    }
  }

  processStamp(event: TouchEvent) {
    var ratioX = 393 / window.outerWidth;
    var ratioY = 852 / window.outerHeight;
    let result = Array.from(event.touches).map((touch) => [
      touch.clientX * ratioX,
      touch.clientY * ratioY,
    ]);

    this.result += "original" + JSON.stringify(event.touches);
    this.result += "scaled" + JSON.stringify(result);
    this.result += "outerWidth" + window.outerWidth;
    this.result += "outerHeight" + window.outerHeight;

    let scannedArray = this.getLinesofPoint(result);
    this._lastScanned = scannedArray;

    let match = this.compareStamps(scannedArray);
    let _this = this;

    if (match != null) {
      this.handleStamp(match);
      _this._read = false;
    } else {
      _this._error = true;
      _this._errorInput = true;
      _this._read = false;
      setTimeout(function () {
        _this._error = false;
      }, 1000);
    }
  }

  handleStamp(stamp = null) {
    switch (this.type) {
      case StampType.Stamp:
        this.api
          .stampLoyality(this.stampStack.id, stamp, this.stampCount)
          .subscribe(
            (data) => {
              this._success = true;
              var _this = this;
              setTimeout(function () {
                _this.closeModal();
              }, 1000);
            },
            (error) => {
              this.sharedService
                .simpleAlert("Fehler", error.errorMessage)
                .then();
            }
          );
        break;
      case StampType.Offer:
        this.api
          .redeemOffer(this.partner.id, this.stampStack.id, stamp)
          .subscribe(
            (data) => {
              this._success = true;
              var _this = this;
              setTimeout(function () {
                _this.closeModal();
              }, 1000);
            },
            (error) => {
              this.sharedService
                .simpleAlert("Fehler", error.errorMessage)
                .then();
            }
          );
        break;
      case StampType.Voucher:
        this.api
          .redeemReward(this.partner.id, this.stampStack.id, stamp)
          .subscribe(
            (data) => {
              this._success = true;
              var _this = this;
              setTimeout(function () {
                _this.closeModal();
              }, 1000);
            },
            (error) => {
              this.sharedService
                .simpleAlert("Fehler", error.errorMessage)
                .then();
            }
          );
        break;
    }
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  toggleSettings() {
    this._settings = !this._settings;
  }

  togglePassCode() {
    this._error_pass = !this._error_pass;
  }

  sendErrorCode(code = null) {
    code = this._errorInput_value;

    this.api
      .stampLog(
        window.navigator.userAgent,
        JSON.stringify(this._lastScanned),
        this.result,
        code,
        this.partner.id
      )
      .subscribe();

    switch (this.type) {
      case StampType.Stamp:
        this.api
          .sendErrorCodeLoyality(
            this.partner.id,
            code,
            this.stampCount,
            this.stampStack.id
          )
          .subscribe(
            (data) => {
              this._success = true;
              var _this = this;
              setTimeout(function () {
                _this.closeModal();
              }, 1000);
            },
            (error) => {
              this.sharedService
                .simpleAlert("Fehler", error.errorMessage)
                .then();
            }
          );
        break;
      case StampType.Offer:
        this.api
          .sendErrorCodeOffer(this.partner.id, code, this.stampCount)
          .subscribe(
            (data) => {
              this._success = true;
              var _this = this;
              setTimeout(function () {
                _this.closeModal();
              }, 1000);
            },
            (error) => {
              this.sharedService
                .simpleAlert("Fehler", error.errorMessage)
                .then();
            }
          );
        break;
      case StampType.Voucher:
        this.api
          .sendErrorCodeReward(this.partner.id, code, this.stampStack.id)
          .subscribe(
            (data) => {
              this._success = true;
              var _this = this;
              setTimeout(function () {
                _this.closeModal();
              }, 1000);
            },
            (error) => {
              this.sharedService
                .simpleAlert("Fehler", error.errorMessage)
                .then();
            }
          );
        break;
    }
    this._error_pass = false;
  }

  getLinesofPoint(array) {
    let length_array = [];
    for (let i0 = 0; i0 < array.length; i0++) {
      let length_point = 0;
      for (let i1 = 0; i1 < array.length; i1++) {
        if (i1 !== i0) {
          length_point += lineLength([array[i0], array[i1]]);
        }
      }
      length_array.push(length_point);
    }
    return length_array.sort((n1, n2) => n1 - n2);
  }

  compareStamps(array) {
    let userArray = array;
    let tolerance = 27;
    for (let i0 = 0; i0 < this.partner.stamps.length; i0++) {
      let stampString = this.partner.stamps[i0].code;
      if (stampString.length > 5) {
        let stampArray = JSON.parse(this.partner.stamps[i0].code);
        let hostArray = JSON.parse(this.partner.stamps[i0].code);
        if (stampArray.length == 2) {
          hostArray = stampArray[0];
        } else {
          hostArray = stampArray;
        }

        this.result += "userArray" + JSON.stringify(userArray);
        this.result += "hostArray" + JSON.stringify(hostArray);

        this.result += "Test Stamp" + i0;

        let valid = true;
        for (let i1 = 0; i1 < hostArray.length; i1++) {
          tolerance = 0.1 * hostArray[i1];

          this.result += "Test Point" + i1 + "\n";
          this.result += userArray[i1] + "\n";
          this.result += hostArray[i1] + "\n";
          this.result += Math.abs(userArray[i1] - hostArray[i1]) + "\n";
          if (Math.abs(userArray[i1] - hostArray[i1]) > tolerance) {
            valid = false;
            this.result += "Test Point" + "False" + "\n";
            continue;
          }
          this.result += "Test Point" + "True" + "\n";
        }

        if (valid) {
          return this.partner.stamps[i0];
        }
      }
    }
    return null;
  }

  polygonArea(vertices, signed = false) {
    let a = 0;

    for (let i = 0, l = vertices.length; i < l; i++) {
      const v0 = vertices[i],
        v1 = vertices[i === l - 1 ? 0 : i + 1];

      a += v0[0] * v1[1];
      a -= v1[0] * v0[1];
    }

    return signed ? a / 2 : Math.abs(a / 2);
  }

  polygonHull(points) {
    if (points.length < 3) {
      return null;
    }

    const pointsCopy = points
      .slice()
      .sort((a, b) => (a[0] === b[0] ? a[1] - b[1] : a[0] - b[0]));

    let lower = [];
    for (let i0 = 0; i0 < pointsCopy.length; i0++) {
      while (
        lower.length >= 2 &&
        cross(
          lower[lower.length - 2],
          lower[lower.length - 1],
          pointsCopy[i0]
        ) <= 0
      ) {
        lower.pop();
      }
      lower.push(pointsCopy[i0]);
    }

    let upper = [];
    for (let i1 = pointsCopy.length - 1; i1 >= 0; i1--) {
      while (
        upper.length >= 2 &&
        cross(
          upper[upper.length - 2],
          upper[upper.length - 1],
          pointsCopy[i1]
        ) <= 0
      ) {
        upper.pop();
      }
      upper.push(pointsCopy[i1]);
    }

    upper.pop();
    lower.pop();

    return lower.concat(upper);
  }

  polygonLength(vertices) {
    if (vertices.length === 0) {
      return 0;
    }

    let i = -1,
      n = vertices.length,
      b = vertices[n - 1],
      xa,
      ya,
      xb = b[0],
      yb = b[1],
      perimeter = 0;

    while (++i < n) {
      xa = xb;
      ya = yb;
      b = vertices[i];
      xb = b[0];
      yb = b[1];
      xa -= xb;
      ya -= yb;
      perimeter += Math.sqrt(xa * xa + ya * ya);
    }

    return perimeter;
  }

  lineAngle(line) {
    return angleToDegrees(
      Math.atan2(line[1][1] - line[0][1], line[1][0] - line[0][0])
    );
  }

  lineLength(line) {
    return Math.sqrt(
      Math.pow(line[1][0] - line[0][0], 2) +
        Math.pow(line[1][1] - line[0][1], 2)
    );
  }

  polygonBounds(polygon) {
    if (polygon.length < 3) {
      return null;
    }

    let xMin = Infinity,
      xMax = -Infinity,
      yMin = Infinity,
      yMax = -Infinity,
      found = false;

    for (let i = 0, l = polygon.length; i < l; i++) {
      const p = polygon[i],
        x = p[0],
        y = p[1];

      if (x != null && isFinite(x) && y != null && isFinite(y)) {
        found = true;
        if (x < xMin) xMin = x;
        if (x > xMax) xMax = x;
        if (y < yMin) yMin = y;
        if (y > yMax) yMax = y;
      }
    }

    return found
      ? [
          [xMin, yMin],
          [xMax, yMax],
        ]
      : null;
  }

  increment() {
    if (this.stampCount != this.stampMax) {
      this.stampCount = this.stampCount + 1;
    }
  }

  decrement() {
    if (this.stampCount != 1) {
      this.stampCount = this.stampCount - 1;
    }
  }
}

export function cross(a, b, o) {
  return (a[0] - o[0]) * (b[1] - o[1]) - (a[1] - o[1]) * (b[0] - o[0]);
}

export function angleToDegrees(angle) {
  return (angle * 180) / Math.PI;
}

export function lineLength(line) {
  return Math.sqrt(
    Math.pow(line[1][0] - line[0][0], 2) + Math.pow(line[1][1] - line[0][1], 2)
  );
}

export function angleToRadians(angle) {
  return (angle / 180) * Math.PI;
}

export function lineAngle(line) {
  return angleToDegrees(
    Math.atan2(line[1][1] - line[0][1], line[1][0] - line[0][0])
  );
}
