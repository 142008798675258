import {Component, EventEmitter, Input, Output} from "@angular/core";
import {ModalController} from "@ionic/angular";

@Component({
  selector: "app-select-fullscreen",
  templateUrl: "./select-fullscreen.component.html",
  styleUrls: ["./select-fullscreen.component.scss"],
})
export class SelectFullscreenComponent {
  @Input() options: any;
  @Output() selected = new EventEmitter<any>();

  searchResults: any;

  constructor(private modalController: ModalController) {}

  search(query: any) {
    if (!query) return (this.searchResults = this.options);
    this.searchResults = this.options.filter(
      (option) =>
        option.name.toLowerCase().includes(query.toLowerCase()) ||
        option.address.city.toLowerCase().includes(query.toLowerCase()) ||
        option.address.street.toLowerCase().includes(query.toLowerCase()) ||
        option.address.house_number
          .toLowerCase()
          .includes(query.toLowerCase()) ||
        option.address.postal_code.toLowerCase().includes(query.toLowerCase())
    );
  }

  dismiss(option?: any) {
    this.modalController.dismiss(option);
  }
}
