import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Platform } from "@ionic/angular";
import { map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { _BASE_URL_ } from "src/environments/environment";
import { StorageService } from "../storage/storage.service";

@Injectable({
  providedIn: "root"
})
export class HttpService {
  constructor(private http: HttpClient, private platform: Platform) {}

  get(endpoint: string, params: any = null): Observable<any> {
    const headers: any = {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "*",
      Accept: "*"
    };
    if (StorageService._userToken) {
      headers["Authorization"] = "Bearer " + StorageService._userToken;
    }
    return this.http
      .get(_BASE_URL_ + endpoint, {
        params: params,
        headers: headers
      })
      .pipe(
        map(data => {
          const resp = <any>data;
          return resp.response;
        })
      );
  }

  getWithHeader(endpoint: string, params: any = null): Observable<any> {
    const headers: any = {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "*",
      Accept: "*"
    };
    if (StorageService._userToken) {
      headers["Authorization"] = "Bearer " + StorageService._userToken;
    }
    return this.http
      .get(_BASE_URL_ + endpoint, {
        params: params,
        headers: headers
      })
      .pipe(
        map(data => {
          const resp = <any>data;
          return resp;
        })
      );
  }

  post(endpoint: string, params: any = {}): Observable<any> {
    const headers: any = {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "*",
      Accept: "*"
    };
    if (StorageService._userToken) {
      headers["Authorization"] = "Bearer " + StorageService._userToken;
    }
    return this.http.post(_BASE_URL_ + endpoint, params, { headers: headers });
  }
}
