export enum SupportedLanguages {
    DE = 'de',
    EN = 'en',
    ES = 'es',
    TR = 'tr',
}

export function getDefaultLanguage(langString: string): SupportedLanguages {
    for (let language in SupportedLanguages) {
        if (langString === SupportedLanguages[language]) {
            return SupportedLanguages[language as keyof typeof SupportedLanguages];
        }
    }
    return SupportedLanguages.EN;   // English by default
}
