import {Component, Inject, OnDestroy} from "@angular/core";
import {
  MenuController,
  NavController,
  PickerController,
  Platform,
} from "@ionic/angular";
import {SplashScreen} from "@ionic-native/splash-screen/ngx";
import {StatusBar} from "@ionic-native/status-bar/ngx";
import {Router} from "@angular/router";
import {DOCUMENT} from "@angular/common";
import {AuthService} from "./services/auth/auth.service";
import {STORAGE_KEY, StorageService} from "./services/storage/storage.service";
import {fromEvent, Observable, Subscription} from "rxjs";
import {debounceTime} from "rxjs/operators";
import {
  getDefaultLanguage,
  SupportedLanguages,
} from "./models/constants/languages";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent implements OnDestroy {
  resizeObservable$: Observable<Event>;
  resizeSubscription$: Subscription;

  navigate: any;
  _user: any;
  isLandscape: boolean;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
    private auth: AuthService,
    private navCtrl: NavController,
    private storage: StorageService,
    public menuCtrl: MenuController,
    private pickerController: PickerController,
    private translateService: TranslateService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.auth.user.subscribe((data) => {
      if (data != null) this._user = (data.user as any).user;
    });

    this.sideMenu();
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      if (this.document.location.pathname != "/") {
        StorageService.BaseURL = this.document.location.pathname
          .replace("/", "")
          .replace("/", "");
      }
      const browserLang = this.translateService.getBrowserLang();
      if (browserLang) {
        this.translateService.setDefaultLang(browserLang);
        this.storage.set(STORAGE_KEY.LANGUAGE, browserLang);
      }

      this.storage.get("authData_" + StorageService.BaseURL).then(
        (data) => {
          if (!data.value) {
            this.router.navigateByUrl("login");
            return;
          } else {
            this.auth.autoLogin().subscribe((data) => {
              this.router.navigateByUrl("tabs/home");
            });
          }
        },
        (err) => {}
      );
    });

    this.resizeObservable$ = fromEvent(window, "resize");
    this.resizeObservable$.pipe(debounceTime(10)).subscribe((evt) => {
      if (
        window.innerWidth > window.innerHeight &&
        window.innerWidth > 500 &&
        !this.platform.is("desktop")
      ) {
        this.isLandscape = true;
      }
      if (window.innerWidth < window.innerHeight) {
        this.isLandscape = false;
      }
    });
  }

  closeMenu() {
    this.menuCtrl.close();
  }

  logout() {
    this.auth.logout();
    this.closeMenu();
  }

  sideMenu() {
    this.navigate = [
      {
        title: "Profil",
        url: "/profile",
        icon: "person-outline",
      },
      {
        title: "Partnersuche",
        url: "/tabs/iframe/partnersuche-app",
        icon: "storefront-outline",
      },
      {
        title: "FAQ",
        url: "/tabs/iframe/faq-app",
        icon: "help-circle-outline",
      },
      {
        title: "AGB",
        url: "/tabs/iframe/agb-app",
        icon: "reader-outline",
      },
      {
        title: "Datenschutz",
        url: "/tabs/iframe/datenschutz-app",
        icon: "reader-outline",
      },
      {
        title: "Impressum",
        url: "/tabs/iframe/impressum-app",
        icon: "reader-outline",
      },
    ];
  }

  ngOnDestroy() {
    this.resizeSubscription$.unsubscribe();
  }

  async selectLanguage() {
    const currentLanguage: SupportedLanguages = getDefaultLanguage(
      this.translateService.getDefaultLang()
    );
    const options = [];
    for (let language in SupportedLanguages) {
      options.push({
        text: this.translateService.instant(
          "language-" + SupportedLanguages[language]
        ),
        value: SupportedLanguages[language],
      });
    }
    const picker = await this.pickerController.create({
      columns: [
        {
          name: "languages",
          selectedIndex:
            Object.values(SupportedLanguages).indexOf(currentLanguage),
          options: options,
        },
      ],
      buttons: [
        {
          text: this.translateService.instant("form-cancelText"),
          role: "cancel",
        },
        {
          text: this.translateService.instant("datefield-done"),
          handler: (value) => {
            const selectedLanguage = value.languages.value;
            this.translateService.setDefaultLang(selectedLanguage);
            this.storage.set(STORAGE_KEY.LANGUAGE, selectedLanguage);
          },
        },
      ],
    });

    await picker.present();
  }

  getCurrentLanguage() {
    return this.translateService.instant(
      "language-" + getDefaultLanguage(this.translateService.getDefaultLang())
    );
  }

  translateFromGerman(page: string) {
    return this.translateService.instant(page);
  }
}
