export const environment = {
  production: true
};

//export const _BASE_URL_ = "https://citystamp.next-levels.de/api";
//export const _BASE_URL_ = "https://localhost/api";
export const _BASE_URL_ = "https://city-stamp.de/api";
export const _GOOGLE_MAPS_KEY_ = "AIzaSyDPe7zfvgy667ScpsTzZtHbQ1MGvxtFzlI";

export const _CREDITIONALS_ = {
  // email: 'slawa.ditzel@next-levels.de',
  //  password: 'test1234'
  email: "",
  password: ""
};

export const _REGISTER_USER_ = {
  first_name: "",
  last_name: "",
  email: "",
  phone_number: "",
  street: "",
  street_number: "",
  postal_code: "",
  city: "",
  password: "",
  password_confirmation: "",
  data_protection_confirm: false
};
