import { Injectable } from "@angular/core";
import { StorageService } from "../storage/storage.service";
import { AlertController, Platform, PopoverController } from "@ionic/angular";
import { InfoPopoverComponent } from "../../components/info-popover/info-popover.component";
import { DateTimeService } from "../date-time/date-time.service";

@Injectable({
  providedIn: "root"
})
export class SharedService {
  _themeColor: string = "";
  mapStyle = [
    {
      featureType: "administrative",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#444444"
        }
      ]
    },
    {
      featureType: "landscape",
      elementType: "all",
      stylers: [
        {
          color: "#f2f2f2"
        }
      ]
    },
    {
      featureType: "poi",
      elementType: "all",
      stylers: [
        {
          visibility: "off"
        }
      ]
    },
    {
      featureType: "road",
      elementType: "all",
      stylers: [
        {
          saturation: -100
        },
        {
          lightness: 45
        }
      ]
    },
    {
      featureType: "road.highway",
      elementType: "all",
      stylers: [
        {
          visibility: "simplified"
        }
      ]
    },
    {
      featureType: "road.arterial",
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off"
        }
      ]
    },
    {
      featureType: "transit",
      elementType: "all",
      stylers: [
        {
          visibility: "off"
        }
      ]
    },
    {
      featureType: "water",
      elementType: "all",
      stylers: [
        {
          color: this._themeColor
        },
        {
          visibility: "on"
        }
      ]
    }
  ];

  constructor(
    private alertCtrl: AlertController,
    public popoverController: PopoverController,
    private platform: Platform,
    private dateTime: DateTimeService
  ) {
    StorageService.Partner.subscribe(partner => {
      if (partner != null) {
        this._themeColor = partner.theme_color;
      }
    });
  }

  async openPopUp(info: string, fullscreen: boolean = false) {
    const popover = await this.popoverController.create({
      component: InfoPopoverComponent,
      cssClass: fullscreen
        ? "popover-class fullscreen-popover"
        : "popover-class",
      translucent: true,
      componentProps: {
        info: info,
        fullscreen: fullscreen
      }
    });
    return await popover.present();
  }

  simpleAlert(title: string, message: string): Promise<any> {
    return new Promise(resolve => {
      let confirm = this.alertCtrl.create({
        header: title,
        message: message,
        buttons: [
          {
            text: "OK",
            handler: () => {
              resolve(true);
            }
          }
        ]
      });
      confirm.then(alert => {
        alert.present();
      });
    });
  }

  isAndroid() {
    return this.platform.is("android");
  }

  isIos() {
    return this.platform.is("ios");
  }

  getEventLength(data) {
    let i = 0;
    if (Array.isArray(data)) {
      data.forEach(date => {
        this.dateTime.isPast(date.expired_at) || date.never_expired
          ? (i += 1)
          : (i = i);
      });
      return i;
    }
    return 0;
  }
}
