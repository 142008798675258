import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./services/auth-guard/auth-guard";

const routes: Routes = [
  {
    path: "",
    loadChildren: () =>
      import("./pages/tabs/tabs.module").then(m => m.TabsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: "login",
    loadChildren: () =>
      import("./pages/auth/login/login.module").then(m => m.LoginPageModule)
  },
  {
    path: "register",
    loadChildren: () =>
      import("./pages/auth/register/register.module").then(
        m => m.RegisterPageModule
      )
  },
  {
    path: "stamp",
    loadChildren: () =>
      import("./pages/stamp/stamp.module").then(m => m.StampPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: "profile",
    loadChildren: () =>
      import("./pages/profile/profile.module").then(m => m.ProfilePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: "forgot-password",
    loadChildren: () =>
      import("./pages/auth/forgot-password/forgot-password.module").then(
        m => m.ForgotPasswordPageModule
      )
  },
  {
    path: "reset-password",
    loadChildren: () =>
      import("./pages/auth/reset-password/reset-password.module").then(
        m => m.ResetPasswordPageModule
      )
  },
  {
    path: "connect",
    loadChildren: () =>
      import("./pages/auth/connect/connect.module").then(
        m => m.ConnectPageModule
      )
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
