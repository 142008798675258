import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DateTimeService {

  constructor() { }

  getDate(date: any) {
    if (!date) {
      return '';
    }
    return moment(date, "YYYY.MM.DD HH:mm:ss").format('DD.MM.YYYY');
  }

  isPast(date: any) {
    return !date ? true : (moment().isSameOrBefore(moment(date)));
  }
}
