import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Plugins } from "@capacitor/core";

const { Storage } = Plugins;

@Injectable({
  providedIn: "root"
})
export class StorageService {
  static _userToken = "";

  static User = new BehaviorSubject(null);
  static Partner = new BehaviorSubject(null);
  static vouchers = new BehaviorSubject(null);
  static offers = new BehaviorSubject(null);
  static BaseURL = "bowling-lounge-mg"; //carwash

  constructor() {}

  async set(key, obj: any) {
    await Storage.set({
      key: key,
      value: JSON.stringify(obj)
    });
  }

  async get(key: string) {
    return await Storage.get({ key: key });
  }

  async remove(key: string) {
    await Storage.remove({ key: key });
  }

  destroy() {
    StorageService.User = new BehaviorSubject(null);
    StorageService.Partner = new BehaviorSubject(null);
  }
}

export const STORAGE_KEY = {
  LANGUAGE: "LANGUAGE"
};
